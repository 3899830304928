<template>
  <div class="v-stack h-stretch gap-3">
    <div class="card light" v-if="project">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <label class="required text-left">Type</label>
          <select v-model="type">
            <option v-for="type in folderTypes" :key="type" :value="type">
              {{ type.toUpperCase() }}
            </option>
          </select>
          <label class="required text-left">Drive</label>
          <Select2 v-model="drive" :options="drives"></Select2>
          <!-- <select v-model="drive">
            <option v-for="drive in drives" :key="drive._id" :value="drive">
              {{ drive.name }}
            </option>
          </select> -->
          <label class="text-left">URL</label>
          <div v-if="drive" class="text-left">
            {{ drive.name }}/{{ project.client.name }}/{{ project.title }}
            {{ project.subtitle }}
          </div>
          <div v-if="!drive" class="text-left">SELECT DRIVE</div>
          <label class="text-left">Description</label>
          <textarea v-model="description" />
          <div class="h-stack h-end">
            <button @click="submit()" class="submit">Add</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import constants from "@/constants.js";
import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      folderTypes: constants.folderTypes,
      type: constants.folderTypes[0],
      drive: null,
      description: "",
      drives: [],
      project: null,
    };
  },
  methods: {
    ...mapActions(["addFolder", "getDrives", "getProject"]),
    submit() {
      this.addFolder({
        type: this.type,
        project: this.id,
        harddrive: this.drive,
        description: this.description,
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDrives()
      .then((drives) => {
        this.drives = drives
          .map((drive) => {
            drive.id = drive._id;
            drive.text = drive.name;
            return drive;
          })
          .sort((a, b) => {
            return a.name.localeCompare(b.name, "cs");
          });
      })
      .catch((error) => {
        console.log(error);
      });

    this.getProject(this.id)
      .then((project) => {
        this.project = project;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>